import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appOnlyPositiveNumber]'
})
export class OnlyPositiveNumberDirective {

  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event']) onInput(event: Event) {
    const inputValue = (event.target as HTMLInputElement).value;
    
    // Remove non-numeric characters and leading zeros
    const sanitizedValue = inputValue.replace(/[^0-9]/g, '').replace(/^0+/, '');

    // Update the input field value
    this.el.nativeElement.value = sanitizedValue;

    // Emit the sanitized value to the model
    // this.emitValue(Number(sanitizedValue));
  }
  @HostListener('change', ['$event']) onChange(event: Event) {
    const inputValue = (event.target as HTMLInputElement).value;
    
    // Remove non-numeric characters and leading zeros
    const sanitizedValue = inputValue.replace(/[^0-9]/g, '').replace(/^0+/, '');

    // Update the input field value
    this.el.nativeElement.value = sanitizedValue;

    // Emit the sanitized value to the model
    // this.emitValue(Number(sanitizedValue));
  }

  private emitValue(value: number): void {
    // You can emit this value to your model or use it as needed
    // For example, you might want to bind this value to a variable in your component
    // and perform further logic or validation with it
    console.log('Sanitized Value:', value);
  }

}
