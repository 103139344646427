import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthService } from "src/app/shared/services/auth.service";
import { PermissionService } from '../../shared/services/permission.service';

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  public newUser = false;
  isSubmitted: boolean = false
  // public user: firebase.User;
  public loginForm: FormGroup;
  public show: boolean = false;
  public errorMessage: any;
  isLoading : boolean = false

  constructor(
    private fb: FormBuilder,
    public router: Router,
    private authService: AuthService,
    private permissionService: PermissionService
  ) {
    this.loginForm = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, Validators.required],
    });
  }

  ngOnInit() {}

  login() {
    this.isSubmitted = true
    if(this.loginForm.invalid) {
      return;
    }
    this.isLoading = true
    const { email, password } = this.loginForm.value;
    this.authService.login({ email: email, password: password }).subscribe({
      next: (res) => {
        if (res["success"] == true) {
          const userData = res["data"];
          this.authService.userData = userData;
          this.permissionService.permission = userData.permission
          sessionStorage.setItem("permission", JSON.stringify(userData.permission));
          sessionStorage.setItem("user", JSON.stringify(userData));
          JSON.parse(sessionStorage.getItem("user")!);
          this.router.navigate(["/dashboard"]);
        } else {
          sessionStorage.setItem("user", "null");
          JSON.parse(sessionStorage.getItem("user")!);
        }
        this.isLoading = false
      },
      error: (err) => {
        this.errorMessage = err;
        this.isLoading = false
      },complete: () =>{
      }
    });
  }

  showPassword() {
    this.show = !this.show;
  }
}
