export class API {
  //!#################################### Base URL #########################################

  public static baseUrl = "https://apicitybox.bulu.in/api/";

  // ! ===================================== Auth ==========================================
  
  public static login = this.baseUrl + "office/login";
  public static register = this.baseUrl + "office/registration";

  
  // ! ===================================== Staff Management ==========================================
  public static addUser = this.baseUrl + "office/save_user";
  public static editUser = this.baseUrl + "office/edit_user";
  public static allUserList = this.baseUrl + "office/user_list";
  public static userDetail = this.baseUrl + "office/user_detail";


  public static addRoles = this.baseUrl + 'office/add_role';
  public static editRoles = this.baseUrl + 'office/edit_role';
  public static allRoles = this.baseUrl + 'office/role_list';
  public static availableRoles = this.baseUrl + 'office/available_role_list';
  public static roleDetail = this.baseUrl + 'office/role_detail';

  // !======================================== Tag Managment ===========================================

  public static allTagGroupList = this.baseUrl + 'office/tag_group_list';
  public static availableTagGroupList = this.baseUrl + 'office/available_tag_group_list';
  public static addTagGroup = this.baseUrl + 'office/add_tag_group';
  public static editTagGroup = this.baseUrl + 'office/edit_tag_group';
  public static tagGroupDetail = this.baseUrl + 'office/tag_group_detail';

  public static allTagGroupTypeList = this.baseUrl + 'office/tag_group_type_list';

  public static allTagList = this.baseUrl + 'office/tag_list';
  public static addTag = this.baseUrl + 'office/add_tag';
  public static editTag = this.baseUrl + 'office/edit_tag';


    // !======================================== Module Managment ===========================================

    public static allModuleList = this.baseUrl + 'office/available_module_list'
    public static availableModuleList = this.baseUrl + 'office/available_module_list'
    public static addModule = this.baseUrl + 'office/add_module'
    
    
    // !======================================== Contact Managment ===========================================
    
    public static allContactGroup = this.baseUrl + 'office/contact_group_list';
    public static availableContactGroup = this.baseUrl + 'office/available_contact_group_list';
    public static addContactGroup = this.baseUrl + 'office/add_contact_group'
    public static editContactGroup = this.baseUrl + 'office/edit_contact_group'
    public static contactGroupDetail = this.baseUrl + 'office/contact_group_detail'
    
    public static allContactList = this.baseUrl + 'office/contact_list';
    public static addContact = this.baseUrl + 'office/add_contact';
    public static editContact = this.baseUrl + 'office/edit_contact';
    public static contactDetail = this.baseUrl + 'office/contact_detail';
    public static editAddress = this.baseUrl + 'office/edit_contact_address';

}
