import { Directive, HostListener, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
  selector: '[appValidateDate]',
  providers: [{ provide: NG_VALIDATORS, useExisting: ValidateDateDirective, multi: true }]
})
export class ValidateDateDirective implements Validator{

  @Input('appValidateDate') currentDate: Date;

  @HostListener('input', ['$event'])
  onInput(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const maxDate = new Date(this.currentDate).toISOString().split('T')[0]
    if (this.currentDate && inputElement.value) {
      const inputDate = inputElement.value ? (new Date(inputElement.value)).toISOString().split('T')[0] : null;
      if (inputDate > maxDate) {
        // Clear the input value if it's a future date
        inputElement.value = '';
      }
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      // Handle empty value (optional)
      return null;
    }

    const inputDate = control.value ? (new Date(control.value)).toISOString().split('T')[0] : null;
    // console.log(this.currentDate);
    const maxDate = new Date(this.currentDate).toISOString().split('T')[0]
    if (inputDate > maxDate) {
      return { futureDate: true };
    }

    return null;
  }

  

}
