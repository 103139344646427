import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { Router } from '@angular/router';
import { User } from '../model/user.model';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  static _router;
  userData: User;

  

constructor(private router : Router) {
  SharedService._router = router
}

  public handleError(errorRes: HttpErrorResponse) {
    let errorMessage = "an error occured";
    if (!errorRes.error || !errorRes.status) {
      return throwError(() => errorMessage);
    }
    console.log(errorRes);
    switch (errorRes.status) {
      case 404:
        errorMessage = errorRes.error.message;
        break;
      case 500:
        errorMessage = "Internal Server Error";
        break;
      case 401:
        errorMessage = "Unauthorized";
        sessionStorage.clear();
        sessionStorage.removeItem("user");
        SharedService._router.navigate(["/auth/login"]);
        break;
    }
    return throwError(() => errorMessage);
  }
}
