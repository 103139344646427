import { Injectable, OnDestroy } from "@angular/core";
import { Subject, BehaviorSubject, fromEvent } from "rxjs";
import { takeUntil, debounceTime } from "rxjs/operators";
import { Router } from "@angular/router";
import { PermissionService } from "./permission.service";

// Menu
export interface Menu {
  headTitle1?: string;
  headTitle2?: string;
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  action?: boolean;
  children?: Menu[];
  actionKey?: string;
  moduleKey?: string;
  default?: boolean

}

@Injectable({
  providedIn: "root",
})
export class NavService implements OnDestroy {
  private unsubscriber: Subject<any> = new Subject();
  public screenWidth: BehaviorSubject<number> = new BehaviorSubject(
    window.innerWidth
  );

  // Search Box
  public search: boolean = false;

  // Language
  public language: boolean = false;

  // Mega Menu
  public megaMenu: boolean = false;
  public levelMenu: boolean = false;
  public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

  // Collapse Sidebar
  public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;
  public adminMenuCollapsed: boolean = false && window.innerWidth < 991;

  // For Horizontal Layout Mobile
  public horizontal: boolean = window.innerWidth < 991 ? false : true;

  // Full screen
  public fullScreen: boolean = false;

  constructor(private router: Router, private permissionService: PermissionService) {
    this.setScreenWidth(window.innerWidth);
    fromEvent(window, "resize")
      .pipe(debounceTime(1000), takeUntil(this.unsubscriber))
      .subscribe((evt: any) => {
        this.setScreenWidth(evt.target.innerWidth);
        if (evt.target.innerWidth < 991) {
          this.collapseSidebar = true;
          this.adminMenuCollapsed = false
          this.megaMenu = false;
          this.levelMenu = false;
        }
        if (evt.target.innerWidth < 1199) {
          this.megaMenuColapse = true;
        }
      });
    if (window.innerWidth < 991) {
      // Detect Route change sidebar close
      this.router.events.subscribe((event) => {
        this.collapseSidebar = true;
        this.megaMenu = false;
        this.levelMenu = false;
      });
    }
    // this.createSideMenu();
  }
  

  createSideMenu() {
    if (this.MENUITEMS.length > 0) {
      this.MENUITEMS.length = 0
    }
    this.ALLMENUITEMS.forEach(item => {
      if (item.default && item.default == true) {
        this.MENUITEMS.push(item);
      } else {
        if (item.moduleKey) {
          const hasPermission = this.permissionService.checkPermission(item.moduleKey, item.actionKey);
          if (hasPermission) {
            this.ADMINMENU.push(item);
          }
        } else if (item.children) {
          const children = [];
          item.children.forEach(childItem => {
            const hasPermission = this.permissionService.checkPermission(childItem.moduleKey, childItem.actionKey);
            if (hasPermission) {
              children.push(childItem);
            }
          })
          if (children.length > 0) {
            Object.assign(item, {children: children})
            this.MENUITEMS.push(item);
          }
        }
      }
    })
    this.ALLADMINMENU.forEach(item => {
      if (item.default && item.default == true) {
        this.ADMINMENU.push(item);
      } else {
        if (item.moduleKey) {
          const hasPermission = this.permissionService.checkPermission(item.moduleKey, item.actionKey);
          if (hasPermission) {
            this.ADMINMENU.push(item);
          }
        }
        else if (item.children) {
          const children = [];
          item.children.forEach(childItem => {
            const hasPermission = this.permissionService.checkPermission(childItem.moduleKey, childItem.actionKey);
            if (hasPermission) {
              children.push(childItem);
            }
          })
          if (children.length > 0) {
            Object.assign(item, {children: children})
            this.ADMINMENU.push(item);
            
          }
        }
      }
    })
  }

  ngOnDestroy() {
    // this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  private setScreenWidth(width: number): void {
    this.screenWidth.next(width);
  }

  ALLMENUITEMS: Menu[] = [
    {
      title: "Dashboard",
      icon: "home",
      type: "link",
      path: "/dashboard",
      active: true,
      default: true
    },
    {
      title: "Contact Management",
      icon: "contact",
      type: "sub",
      active: false,
      children: [
        {
          path: "/contact-management/contact-group",
          title: "Contact Group",
          type: "link",
          actionKey: "ContactGroupList",
          moduleKey: "ContactGroup",

        },
        {
          path: "/contact-management/contact-detail",
          title: "Contacts",
          type: "link",
          actionKey: "ContactList",
          moduleKey: "Contact",
        },
      ],
    },
    {
      title: "Admin",
      icon: "icons",
      type: "sub",
      action: true,
      default: true
    },
  ];

  MENUITEMS: Menu[] = []
  ADMINMENU: Menu[] = []

  ALLADMINMENU: Menu[] = [
    {
      title: "Staff Management",
      icon: "user",
      type: "sub",
      active: false,
      children: [
        { path: "/staff-management/roles", title: "Roles", type: "link", "moduleKey": "Role", "actionKey": "RoleList", },
        { path: "/staff-management/users", title: "Users", type: "link",  "moduleKey": "User", "actionKey": "UserList", },
      ],
    },
    {
      title: "Tag",
      icon: "project",
      type: "link",
      active: false,
      path: '/tag-management/tag-group',
      "moduleKey": "TagGroup",
      "actionKey": "TagGroupList",
    },
  ];

  // Array
  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
  adminItems = new BehaviorSubject<Menu[]>(this.ADMINMENU);
}
