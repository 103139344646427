import { Component, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { AuthService } from '../../../../services/auth.service';
import { filter } from "rxjs";
import { SharedService } from "src/app/shared/services/shared.service";

@Component({
  selector: "app-my-account",
  templateUrl: "./my-account.component.html",
  styleUrls: ["./my-account.component.scss"],
})
export class MyAccountComponent implements OnInit {
  public userName: string;
  public userType: string;
  public profileImg: "assets/images/dashboard/profile.jpg";

  constructor(public router: Router, private authService: AuthService, private sharedService: SharedService) {
    if (JSON.parse(sessionStorage.getItem("user"))) {
      console.log(true);
      this.userName = JSON.parse(sessionStorage.getItem("user"))['username']
      this.userType = JSON.parse(sessionStorage.getItem("user"))['user_type']['userTypeTitle']
    } else {
      console.log(false);
    }
    this.sharedService.userData = this.authService.getUserData();
  }

  ngOnInit() {
  }

  logoutFunc() {
    this.authService.signOut();
  }

  
}
