<div class="container">

    <div class="row p-md-5 justify-content-center align-items-center h-100">
        <div class="col-md-8 py-3 ">
            <div class="row">
                <div class="col-md-12 text-center mb-4">
                    <img src="/assets/images/auth-page/logo.png" alt="" class="img-fluid" width="20%">
                </div>
            </div>
            <div class="row justify-content-center mb-4">
                <div class="col-md-12 text-center">
                    <h4>Register Now</h4>
                    <p class="text-muted">Create a new account</p>
                </div>
                <div class="col-md-6 text-center">
                    <div class="circle active">1</div>
                    <div class="circle" [ngClass]="currentPage==2 ? 'active' : ''">2</div>
                </div>
            </div>
            <form [formGroup]="registerForm">
                <ng-container *ngIf="currentPage==1">
                    <div class="row justify-content-center px-md-5">
                        <div class="col-md-10">
                            <div class="form-group mb-3">
                                <label for="" class="form-label">Business Name<sup class="text-danger">*</sup></label>
                                <input type="text" name="" class="form-control rounded-5" id=""
                                    placeholder="Registered Business Name" formControlName="companyName">
                            </div>
                        </div>
                        <div class="col-md-10">
                            <div class="form-group mb-3">
                                <label for="" class="form-label">Your Name<sup class="text-danger">*</sup></label>
                                <input type="text" name="" class="form-control rounded-5" id=""
                                    placeholder="Eg: John Albert" formControlName="contactPerson">
                            </div>
                        </div>
                        <div class="col-md-10">
                            <div class="form-group mb-3">
                                <label for="" class="form-label">Email<sup class="text-danger">*</sup></label>
                                <input type="text" name="" class="form-control rounded-5" id=""
                                    placeholder="Eg: john@domain.com" formControlName="email">
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div class="form-group mb-3">
                                <label for="" class="form-label">Mobile Number<sup class="text-danger">*</sup></label>
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <select class="form-control rounded-start-5 country-code"
                                            formControlName="extCodeMobileNo">
                                            <!-- <option value="+91">🇮🇳 +91</option> -->
                                            <option value="{{ country.dial_code }}" *ngFor="let country of lstCountry">
                                                {{ country.flag+" "+country.dial_code }}</option>
                                            <!-- Add more options for other country codes as needed -->
                                        </select>
                                    </div>
                                    <input class="form-control rounded-end-5" type="tel" placeholder="88XXXXXXXX"
                                        onlyNumbers maxlength="10" formControlName="mobileNo" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div class="form-group mb-3">
                                <label for="" class="form-label">WhatsApp Number</label>
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <select class="form-control rounded-start-5"
                                            formControlName="extCodeWhatsAppNo">
                                            <option value="{{ country.dial_code }}" *ngFor="let country of lstCountry">
                                                {{ country.flag+" "+country.dial_code }}</option>
                                        </select>
                                    </div>
                                    <input class="form-control rounded-end-5" type="tel" placeholder="88XXXXXXXX"
                                        onlyNumbers maxlength="10" formControlName="whatsAppNo" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div class="form-group mb-3">
                                <label for="" class="form-label">Password<sup class="text-danger">*</sup></label>
                                <input type="password" name="" class="form-control rounded-5" id=""
                                    placeholder="Password" formControlName="password">
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div class="form-group mb-3">
                                <label for="" class="form-label">Confirm Password<sup
                                        class="text-danger">*</sup></label>
                                <input type="password" name="" class="form-control rounded-5" id=""
                                    placeholder="Confirm Password" formControlName="confirm_password">
                                <small class="text-danger"
                                    *ngIf="f.confirm_password.touched && registerForm.hasError('passwordMismatch')">Password
                                    and Confirm Password does not match</small>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center align-items-center px-md-5">
                        <div class="col-md-7 order-md-1 order-2 text-center text-md-start">
                            <div class="form-group my-4">
                                <p>Already have Account? <a routerLink="/auth/login">Login</a></p>
                            </div>
                        </div>
                        <div class="col-md-3 order-md-2 order-1 text-md-end">
                            <div class="form-group my-4">
                                <button class="btn btn-dark w-100 rounded-5" (click)="changePage('n')"
                                    type="button"><span class="d-md-none">Continue </span> <i
                                        class="fa fa-arrow-right"></i></button>
                            </div>
                            <!-- <div class="form-group my-4 d-none d-md-block">
                            <button class="btn btn-dark rounded-5" (click)="changePage('n')"><i class="fa fa-arrow-right"></i></button>
                        </div> -->
                        </div>
                    </div>
                </ng-container>
            </form>
            <form [formGroup]="registerFormSec" (ngSubmit)="onSubmit()">
                <ng-container *ngIf="currentPage==2">
                    <div class="row justify-content-center px-md-5">
                        <div class="col-md-10">
                            <div class="form-group mb-3">
                                <label for="" class="form-label">Address Line 1<sup class="text-danger">*</sup></label>
                                <input type="text" name="" class="form-control rounded-5" id=""
                                    placeholder="Address line 1" formControlName="addressLine1">
                            </div>
                        </div>
                        <div class="col-md-10">
                            <div class="form-group mb-3">
                                <label for="" class="form-label">Address Line 2</label>
                                <input type="text" name="" class="form-control rounded-5" id=""
                                    placeholder="Address Line 2" formControlName="addressLine2">
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div class="form-group mb-3">
                                <label for="" class="form-label">Village</label>
                                <input type="text" name="" class="form-control rounded-5" id=""
                                    placeholder="Enter Village" formControlName="village">
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div class="form-group mb-3">
                                <label for="" class="form-label">City<sup class="text-danger">*</sup></label>
                                <input type="text" name="" class="form-control rounded-5" id="" placeholder="Eg: Mumbai"
                                    formControlName="city">
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div class="form-group mb-3">
                                <label for="" class="form-label">District<sup class="text-danger">*</sup></label>
                                <input type="text" name="" class="form-control rounded-5" id="" placeholder="Eg: Mumbai"
                                    formControlName="district">
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div class="form-group mb-3">
                                <label for="" class="form-label">State<sup class="text-danger">*</sup></label>
                                <input type="text" name="" class="form-control rounded-5" id=""
                                    placeholder="Eg: Maharastra" formControlName="state">
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div class="form-group mb-3">
                                <label for="" class="form-label">Pincode<sup class="text-danger">*</sup></label>
                                <input type="text" name="" class="form-control rounded-5" id="" placeholder="Eg: 400001"
                                    formControlName="pincode">
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div class="form-group mb-3">
                                <label for="" class="form-label">Country<sup class="text-danger">*</sup></label>
                                <!-- <input type="text" name="" class="form-control rounded-5" id=""
                                    placeholder="Eg: India" formControlName="country"> -->
                                <ng-select [items]="lstCountry" bindLabel="name" bindValue="name"
                                    formControlName="country" class="js-example-basic-single register-page"
                                    placeholder="country"></ng-select>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center align-items-center px-md-5">
                        <div class="col-md-3 col-2">
                            <div class="form-group my-4">
                                <button class="btn btn-dark rounded-5" (click)="changePage('p')"><i
                                        class="fa fa-arrow-left"></i></button>
                            </div>
                        </div>
                        <div class="col-md-7 col-10 text-md-end">
                            <div class="form-group my-4">
                                <button class="btn btn-dark w-100 rounded-5" [disabled]="isLoading"
                                type="submit">
                                    <span *ngIf="!isLoading">Sign Up</span>
                                    <div *ngIf="isLoading" class="loader-box">
                                        <div class="loader-18"></div>
                                    </div>
                                </button>
                                <!-- <button class="btn btn-dark w-100 rounded-5" type="submit"></button> -->
                            </div>
                        </div>
                    </div>
                </ng-container>
            </form>
        </div>
        <div class="col-md-6 text-center position-relative">
            <div class="position-absolute w-100" *ngIf="errorMessage">
                <div class="alert alert-danger alert-dismissible fade show" role="alert">
                    <p class="fs-6">{{ errorMessage }}</p>
                    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>
            </div>
        </div>
        <!-- <div class="col-md-6 text-center d-none d-md-block h-100 background-image">
            </div> -->
    </div>
</div>