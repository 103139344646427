import { Injectable } from '@angular/core';
import { Module } from '../model/module.model';


@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  public permission: Module[]

  checkPermission(moduleKey: string, actionKey: string): boolean {
    if(!this.permission){
      this.permission = JSON.parse(sessionStorage.getItem('permission'))
    }
    const module = this.permission.find(el => el.moduleKey == moduleKey)
    if(!module) {return false;}
    const action = module.module_action.find(a => a.actionKey == actionKey);
    if (action && action.hasPermission) {
      if (action.hasPermission != 1) {
        return false
      }
      return true;
    }
    return false;
  }


  getPermissionOfModule(moduleKey: string,){
    if(!this.permission){
      this.permission = JSON.parse(sessionStorage.getItem('permission'))
    }
    const module = this.permission.find(el => el.moduleKey == moduleKey)
    return module.module_action;
  }

  constructor() { }
}
