<!-- <div class="container-fluid p-0">
  <div class="row">
    <div class="col-12">
      <div class="login-card">
        <div>
          <div>
            <a class="logo" routerLink="/">
              <img class="img-fluid for-light" src="assets/images/logo/logo.png" alt="looginpage" />
              <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="looginpage" />
            </a>
          </div>
          <div class="login-main">
            <form class="theme-form" [formGroup]="loginForm">
              <h4>Sign in to account</h4>
              <p>Enter your email & password to login</p>
              <div class="form-group">
                <label class="col-form-label">Email Address</label>
                <input class="form-control" type="email" required="" placeholder="app@yourmail.com" formControlName="email" />
                <div *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.required" class="text text-danger mt-1">Email is required</div>
                <div *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.email" class="text text-danger mt-1">Invalid Email</div>
              </div>
              <div class="form-group">
                <label class="col-form-label">Password</label>
                <input class="form-control" [type]="show ? 'text' : 'password'" formControlName="password" required="" placeholder="*********" />
                <div class="show-hide" (click)="showPassword()" *ngIf="!show"><span class="show"></span></div>
                <div class="show-hide" (click)="showPassword()" *ngIf="show"><span class="Hide"></span></div>
                <div *ngIf="loginForm.controls.password.touched && loginForm.controls.password.errors?.required" class="text text-danger mt-1">Password is required</div>
              </div>
              <div class="form-group mb-0">
                <div class="checkbox p-0">
                  <input id="checkbox1" type="checkbox" />
                  <label class="text-muted" for="checkbox1">Remember password</label>
                </div>
                <a [routerLink]="'/authentication/forgot-password'" class="link">Forgot password?</a>
                <div class="text-end mt-3">
                  <button class="btn btn-primary d-block w-100" [disabled]="!loginForm.valid" (click)="login()" type="button"><span>Sign in</span></button>
                </div>
              </div>
              <h6 class="text-muted mt-4 or">Or Sign in with</h6>
              <div class="social mt-4">
                <div class="btn-showcase">
                  <a class="btn btn-light" target="_blank"> <app-feather-icons [icon]="'log-in'" class="txt-google"></app-feather-icons>Google + </a>
                  <a class="btn btn-light" target="_blank"> <app-feather-icons [icon]="'facebook'" class="txt-fb"></app-feather-icons>Facebook </a>
                  <a class="btn btn-light" target="_blank"> <app-feather-icons [icon]="'twitter'" class="txt-twitter"></app-feather-icons>twitter </a>
                </div>
              </div>
              <p class="mt-4 mb-0">
                Don't have account?
                <a [routerLink]="'/authentication/register/simple'" class="ms-2">Create Account</a>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->
<div class="container">
  <div class="row p-md-5 align-items-center h-100">
    <div class="col-md-6 py-3">
      <form class="theme-form" [formGroup]="loginForm">
        <div class="row justify-content-center">
          <div class="col-md-12 text-center mb-3">
            <!-- <h1>Logo</h1> -->
            <img src="/assets/images/auth-page/logo.png" alt="" class="img-fluid" width="40%">
          </div>
          <div class="col-md-12 text-center mb-5">
            <h4>Welcome to Citybox</h4>
            <p class="text-muted">Enter your Email and Password to login</p>
          </div>
          <div class="col-md-8">
            <div class="form-group mb-3">
              <label for="" class="form-label">Email <sup class="text-danger">*</sup></label>
              <input type="email" name="" class="form-control rounded-5" id="" placeholder="Enter your Email"
                formControlName="email" required>
              <div *ngIf="isSubmitted && loginForm.controls.email.errors?.required" class="text text-danger mt-1">Email
                is required</div>
              <div *ngIf="isSubmitted && loginForm.controls.email.errors?.email" class="text text-danger mt-1">Invalid
                Email</div>
            </div>
          </div>
          <div class="col-md-8">
            <div class="form-group mb-3">
              <label for="" class="form-label">Password <sup class="text-danger">*</sup></label>
              <input type="password" name="" class="form-control rounded-5" id="" placeholder="Enter your Password"
                required formControlName="password">
              <div *ngIf="isSubmitted && loginForm.controls.password.errors?.required" class="text text-danger mt-1">
                Password is required</div>
            </div>
          </div>
          <div class="col-md-8">
            <div class="form-group my-4">
              <button class="btn btn-dark w-100 rounded-5" [disabled]="isLoading" (click)="login()"
                type="button">
                <span *ngIf="!isLoading">Login</span>
                <div *ngIf="isLoading" class="loader-box">
                  <div class="loader-18"></div>
                </div>
              </button>
              <!-- <button class="btn btn-dark w-100 rounded-5" (click)="login()">Login</button> -->
            </div>
          </div>
          <div class="col-md-8 text-center">
            <div class="form-group mb-3">
              <p>Don't have a Account ? <a routerLink="/auth/register">Sign Up</a></p>
            </div>
          </div>
          <div class="col-md-8 text-center position-relative d-flex justify-content-center">
            <div class="position-absolute w-100" *ngIf="errorMessage">
              <div class="alert alert-danger alert-dismissible fade show" role="alert">
                <p class="fs-6">{{ errorMessage }}</p>
                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="col-md-6 text-center d-none d-md-block h-100 background-image">
    </div>
  </div>
</div>