import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Router } from "@angular/router";
import { API } from "../API/api";
import { User } from "../model/user.model";
import {  catchError, throwError } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(private http: HttpClient, private router: Router) {}

  userData: User;

  getUserData() {
    this.userData = JSON.parse(sessionStorage.getItem("user")!);
    return this.userData;
  }

  login(user: { email: string; password: string }) {
    return this.http.post(API.login, user).pipe(catchError(this.handleError));
  }

  signUp(body) {
    return this.http.post(API.register, body).pipe(catchError(this.handleError));
  }

  public signOut() {
    sessionStorage.clear();
    sessionStorage.removeItem("user");
    this.router.navigate(["/auth/login"]);
  }

  private handleError(errorRes: HttpErrorResponse) {
    let errorMessage = "an error occured";
    if (!errorRes.error || !errorRes.status) {
      return throwError(() => errorMessage);
    }
    switch (errorRes.status) {
      case 404:
        errorMessage = errorRes.error.message;
        break;
      case 500:
        errorMessage = "Internal Server Error";
        break;
      case 401:
        errorMessage = "Unauthorized";
        this.signOut();
        break;
    }
    return throwError(() => errorMessage);
  }
}
