import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-permission-denied',
  templateUrl: './permission-denied.component.html',
  styleUrls: ['./permission-denied.component.scss']
})
export class PermissionDeniedComponent implements OnInit {

  returnUrl: string;

  constructor(private route: ActivatedRoute, private router: Router, private location : Location) {}

  ngOnInit(): void {
    // Retrieve the attempted URL from the query parameters
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  navigateBack(): void {
    // Navigate back to the attempted URL or a default route
    this.location.back();
    // this.router.navigateByUrl(this.returnUrl);
  }

}
