import { Component } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/shared/services/auth.service';
import { countries } from 'country-list-json';
import { Router } from '@angular/router';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent {
  isSubmitted: boolean = false;
  currentPage: number = 1;
  registerForm: FormGroup;
  registerFormSec: FormGroup;
  errorMessage: string;
  isLoading: boolean = false

  lstCountry = countries

  constructor(private authService: AuthService, private router: Router) {
    this.registerForm = new FormGroup({
      'companyName': new FormControl(null, Validators.required),
      'contactPerson': new FormControl(null, Validators.required),
      'email': new FormControl(null, Validators.required),
      'mobileNo': new FormControl(null, Validators.required),
      'whatsAppNo': new FormControl(null),
      'password': new FormControl(null, Validators.required),
      'confirm_password': new FormControl(null, Validators.required),
      'extCodeMobileNo': new FormControl('+91'),
      'extCodeWhatsAppNo': new FormControl('+91'),
    }, { validators: this.passwordMatchValidator });


    this.registerFormSec = new FormGroup({
      'addressLine1': new FormControl(null, Validators.required),
      'addressLine2': new FormControl(null),
      'village': new FormControl(null),
      'city': new FormControl(null, Validators.required),
      'district': new FormControl(null, Validators.required),
      'state': new FormControl(null, Validators.required),
      'country': new FormControl(null, Validators.required),
      'pincode': new FormControl(null, Validators.required),
    })
  }

  passwordMatchValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const password = control.get('password');
    const confirmPassword = control.get('confirm_password');

    if (password.value !== confirmPassword.value) {
      return { 'passwordMismatch': true };
    }

    return null;
  }

  get f() {
    return this.registerForm.controls
  }


  changePage(v) {
    if (v == 'n') {
      this.registerForm.markAllAsTouched();
      if (!this.registerForm.invalid) {
        this.currentPage++;
      }
    } else {
      this.currentPage--;
    }
  }

  onSubmit() {
    this.isSubmitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    this.isLoading = true
    const firstFormValue = this.registerForm.value
    const secondFormValue = this.registerFormSec.value
    const body = {
      'companyName': firstFormValue['companyName'],
      'contactPerson': firstFormValue['contactPerson'],
      'email': firstFormValue['email'],
      'mobileNo': firstFormValue['mobileNo'],
      'whatsAppNo': firstFormValue['whatsAppNo'],
      'extCodeMobileNo': firstFormValue['extCodeMobileNo'],
      'extCodeWhatsAppNo': firstFormValue['extCodeWhatsAppNo'],
      'password': firstFormValue['password'],
      'addressLine1': secondFormValue['addressLine1'],
      'addressLine2': secondFormValue['addressLine2'],
      'village': secondFormValue['village'],
      'city': secondFormValue['city'],
      'state': secondFormValue['state'],
      'country': secondFormValue['country'],
      'pincode': secondFormValue['pincode'],
      'district': secondFormValue['district'],
      'countryCode': countries.find(country => country.name === secondFormValue['country'])?.code,
      'isActive': 1,
      'isDisplay': 1
    }

    Object.keys(body).forEach(
      (el) => (body[el] == null || body[el] == "") && delete body[el]
    );

    this.authService.signUp(body).subscribe({
      next: (res) => {
        if (res["success"] == true) {
          this.router.navigate(['/auth/login']);
        }
        this.isLoading = false
      },
      error: (err) => {
        console.log(err);
        this.isLoading = false
        this.errorMessage = err;
      }, complete: () => {
        this.isLoading = false
        console.log("in complete", this.isLoading);
      }
    });
  }

}

