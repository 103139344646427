import { Component, Input, OnInit } from '@angular/core';


@Component({
  selector: 'app-mct-toast',
  templateUrl: './mct-toast.component.html',
  styleUrls: ['./mct-toast.component.scss']
})
export class MctToastComponent implements OnInit {
  ngOnInit(): void {
    this.myToast()
  }
  @Input() Type:any = 'error';
  @Input() Text:any = '!!! Hello From My Toast';
  @Input() TimeOut:number = 5;
  @Input() Slide:any = 'topToBottom';

  showHideClass: string

  closeToast(){
    this.showHideClass = this.Slide+'-slide-hide'
    // $('.toast-custom').addClass(this.Slide+'-slide-hide')
    // $('.toast-custom').removeClass(this.Slide+'-slide-show');
  }

  myToast() {
    setTimeout(() => {
      this.showHideClass = this.Slide+'-slide-show'
      // $('.toast-custom').removeClass(this.Slide+'-slide-hide');
      // $('.toast-custom').addClass(this.Slide+'-slide-show')
    },0);
    setTimeout(() => {
      this.closeToast()
    }, this.TimeOut * 1000);
  }

}
